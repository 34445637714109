import React from 'react';
import {
  Button,
  Stack,
  Flex,
  Text,
  Avatar,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/core';

export const ArthurProfile = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing="10px">
      <Flex align="center" direction={['column', 'column', 'row']}>
        <Avatar size="2xl" mr="30px" my={['20px', '20px', 0]} />
        <Text color="#ccc">
          Arthur Watson has been awarded contracts totalling +$30,000,000 and
          holds a Certificate IV in Training and Assessment TAA40104. With more
          than 30-years experience working in a professional capacity in the
          asbestos assessment and removal industry, Arthur has worked
          extensively in the United States and Australia.
        </Text>
      </Flex>

      {isOpen && (
        <Stack spacing="10px">
          <Text color="#999999">
            He helped manage the inspection of 23 million square feet of
            building space and asbestos-containing building materials inspection
            under the Asbestos Hazard Emergency Response Act (AHERA)
          </Text>

          <Text color="#999999">
            He has held the position of nominated supervisor for Jesco Asbestos
            Removal since 2016; was joint Managing Director and License Holder
            for AAC Environmental from 2004 to 2015 and was also Managing
            Director/License Holder for BRS Asbestos Removal, NSW/ACT, Australia
            from 1988 – 1993
          </Text>

          <Text color="#999999">
            Arthur has project managed an extensive list of asbestos projects,
            covering all requirement levels from small-scale to large
            assignments and everything in between.
          </Text>

          {/* <Text color="#ccc">Extensive experience</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              Asbestos Supervisor – AAC, Inc., Minneapolis, MN, USA
            </ListItem>
            <ListItem>
              carrying out asbestos surveys (sampling, identification, risk
              assessment, control measures)
            </ListItem>
            <ListItem>
              carrying out control and clearance air monitoring
            </ListItem>
            <ListItem>
              providing pre and post removal visual clearances
            </ListItem>
          </List>

          <Text color="#ccc">Local project samples include</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              ACT ‘Mr Fluffy’ remediation (both government and private)
            </ListItem>
            <ListItem>ACT hospitals</ListItem>
            <ListItem>Residential premises within ACT and NSW</ListItem>
            <ListItem>Commercial buildings</ListItem>
            <ListItem>Commercial buildings</ListItem>
          </List>

          <Text color="#ccc">Other Skills and Positions</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              Preparing asbestos removal control plans (ARCP) and Safe Work
              Method Statements (SWMS)
            </ListItem>
            <ListItem>
              Project Manager/Technical Advisor on large and small-scale
              asbestos removal projects
            </ListItem>
            <ListItem>
              Project Manager/ Technical Advisor on large and small-scale soil
              contamination projects (sampling, identification, risk assessment,
              control measures)
            </ListItem>
            <ListItem>
              Former instructor for Master Builders Association “Asbestos
              Awareness” training course
            </ListItem>
          </List>

          <Text color="#ccc">Specialised Education</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              P402 – Buildings Surveys and Bulk Sampling for Asbestos, British
              Occupational Hygiene Society
            </ListItem>
            <ListItem>
              Georgia Tech Occupational Safety & Health Program, Advanced
              Supervision of Abatement
            </ListItem>
            <ListItem>
              Georgia Tech Occupational Safety & Health Program, Lead Abatement
            </ListItem>
            <ListItem>
              Georgia Tech Occupational Safety & Health Program, Asbestos
              Inspections
            </ListItem>
            <ListItem>Asbestos Hazard Emergency Response Training</ListItem>
          </List>

          <Text color="#ccc">Professional Qualifications</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              Current ACT Licensed Asbestos Assessor No. AA00009
            </ListItem>
            <ListItem>
              Current Technical Assessor for Surveys and Sampling, National
              Association of Testing Authorities (NATA)
            </ListItem>
            <ListItem>
              Retired (2010 - 2019) Cert IV instructor in Asbestos Awareness for
              the Master Builders Association (ACT)
            </ListItem>
            <ListItem>
              Former ACT Licensed Asbestos Removalist, Class A No. 19925882
            </ListItem>
            <ListItem>
              Former NSW Licensed Asbestos Removalist, Class B No. 210545
            </ListItem>
            <ListItem>Former Chairman, ACT Asbestos Advisory Board</ListItem>
          </List>

          <Stack mt="20px">
            <Text color="white">Lic ARA00009</Text>
          </Stack> */}
        </Stack>
      )}

      <Button
        variantColor="transparent"
        variant="solid"
        mt={10}
        onClick={onToggle}
      >
        {isOpen ? 'Show less' : 'Show more'}
      </Button>
    </Stack>
  );
};
