import React from 'react';
import {
  Button,
  Stack,
  Box,
  Flex,
  Link,
  Image,
  Text,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { FaEye, FaStar, FaRibbon } from 'react-icons/fa';
import GatsbyImage from 'gatsby-image';
import headerBG from '../images/home-header.png';
import heroAbout from '../images/home-about-2.png';
import homeMap from '../images/home-map.png';
import mapPin from '../images/map-pin.png';
import mapBubble from '../images/bubble.png';
import rectangleAbout from '../images/home-rectangle-about.png';
import { JillProfile } from './JillProfile';
import { ArthurProfile } from './ArthurProfile';

export const HomePage = () => {
  const {
    heroImage: {
      childImageSharp: { fluid: heroImage },
    },
  } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "home-main-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  console.log(heroImage);

  return (
    <Flex bg="#F6F6F6" align="center" position="relative" direction="column">
      <Box
        id="home"
        maxWidth="1200px"
        height={['450px', '450px', '600px']}
        width="100%"
        zIndex={2}
      >
        <Box
          position="absolute"
          top="0"
          right="0"
          left="0"
          zIndex={-1}
          height={[400, 400, 550]}
          backgroundImage={`url(${headerBG})`}
          backgroundPosition="bottom center"
        />
        <SimpleGrid columns={[1, 1, 1, 5]}>
          <Flex
            gridColumn={['span 1', 'span 1', 'span 1', 'span 2']}
            direction="column"
            align="flex-end"
            color="white"
            px={['10px', '20px', '30px', 'none']}
          >
            <Stack
              maxWidth={['550px', '550px', '550px', '400px']}
              spacing="20px"
              margin={['0 auto', '0 auto', '0 auto', '0']}
              textAlign={['center', 'center', 'center', 'initial']}
              align={['center', 'center', 'center', 'initial']}
            >
              <Text
                as="h1"
                fontWeight="bold"
                color="#D8D8D8"
                fontSize={['2.5rem', '2.5rem', '2.5rem', '3rem']}
                lineHeight={1}
                pt={['3rem', '3rem', '5.5rem']}
              >
                Safety and compliance are in Jesco’s
                <Text as="span" color="secondary" ml="9px">
                  D
                </Text>
                <Text as="span" color="primary">
                  N
                </Text>
                <Text as="span" color="tertiary">
                  A
                </Text>
                .
              </Text>

              <Text as="h2" color="#D8D8D8" fontSize="1rem" lineHeight={1}>
                All team members are guided through Jesco’s strategy on health
                and safety and the measures they have adopted to prevent risks
                associated with toxic fibres.
              </Text>

              <Link
                href="mailto: admin@jescoasbestos.com.au"
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  mt="20px"
                  rounded="30px"
                  width="150px"
                  variant="solid"
                  color="#0F1112"
                  variantColor="hero"
                  fontSize="1.1rem"
                >
                  Contact Us
                </Button>
              </Link>
            </Stack>
          </Flex>
          <Flex
            display={['none', 'none', 'none', 'flex']}
            gridColumn={['span 1', 'span 1', 'span 1', 'span 3']}
            flex={2}
            align="center"
            justify="flex-end"
            pr="20px"
          >
            <Box
              width={[600]}
              boxShadow="5px 5px 15px rgba(0, 0, 0, .1)"
              overflow="hidden"
              rounded="md"
            >
              <GatsbyImage fluid={heroImage} alt="Main hero" />
            </Box>
          </Flex>
        </SimpleGrid>
      </Box>

      <Box id="about" maxWidth="1200px" width="100%">
        <SimpleGrid columns={[1, 1, 2]}>
          <Stack
            spacing="20px"
            width="100%"
            maxWidth="500px"
            margin={['0 auto', '0 auto', 'auto']}
          >
            <Flex
              paddingY="20px"
              paddingX="40px"
              bg="white"
              boxShadow="2px 5px 20px rgba(0, 0, 0, .1)"
              rounded="md"
              direction="column"
            >
              <Flex align="center" mb="10px">
                <Box
                  mr="10px"
                  fontSize="1.3rem"
                  as={FaRibbon}
                  color="primary"
                />
                <Text
                  as="h1"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  color="#0F1112"
                >
                  Expertise
                </Text>
              </Flex>
              <Text as="h2" fontSize="1rem" lineHeight={1.3} color="#5A5E63">
                With over 30 years of experience in the asbestos industry, there
                is no one better to trust than Jesco with your project.
              </Text>
            </Flex>

            <Flex
              paddingY="20px"
              paddingX="40px"
              bg="white"
              boxShadow="2px 5px 20px rgba(0, 0, 0, .1)"
              rounded="md"
              direction="column"
            >
              <Flex align="center" mb="10px">
                <Box mr="10px" fontSize="1.3rem" as={FaEye} color="secondary" />
                <Text
                  as="h1"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  color="#0F1112"
                >
                  Vision
                </Text>
              </Flex>
              <Text as="h2" fontSize="1rem" lineHeight={1.3} color="#5A5E63">
                The company’s vision is to build on Jesco’s existing strength,
                vast experience and uncompromising commitment to safety, while
                developing more efficient and economic asbestos assessment and
                removal practices.
              </Text>
            </Flex>

            <Flex
              paddingY="20px"
              paddingX="40px"
              bg="white"
              boxShadow="2px 5px 20px rgba(0, 0, 0, .1)"
              rounded="md"
              direction="column"
            >
              <Flex align="center" mb="10px">
                <Box mr="10px" fontSize="1.3rem" as={FaStar} color="tertiary" />
                <Text
                  as="h1"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  color="#0F1112"
                >
                  Mission
                </Text>
              </Flex>
              <Text as="h2" fontSize="1rem" lineHeight={1.3} color="#5A5E63">
                To provide a safe environment for everyone, including Jesco
                staff, by adhering to world standard best practices and through
                delivering cleaner, safer results on every project.
              </Text>
            </Flex>
          </Stack>

          <Stack
            pl={[0, 0, '100px']}
            spacing="20px"
            width="100%"
            maxWidth="500px"
            margin="0 auto"
            textAlign={['center', 'center', 'initial']}
            px={['.5rem', '1rem', '3rem', 'initial']}
          >
            <Text
              as="h2"
              fontWeight="bold"
              color="#0F1112"
              fontSize="2rem"
              lineHeight={1}
              mb="1.5rem"
              pt={['4.5rem', '4.5rem', '4.5rem', '1.5rem']}
            >
              Asbestos certainly has a disturbing history, Jesco wants to ensure
              it has no future.
            </Text>

            <Text as="h3" color="#5A5E63">
              Jesco was formed to ensure that the devastation asbestos has
              caused to residents across the ACT and NSW regions are minimised.
            </Text>

            <Text as="h3" color="#5A5E63">
              Jill Krogh has built the company with a commitment to providing
              uncompromising service with a set of standards that define her
              attitude to safety and human value.
            </Text>
          </Stack>
        </SimpleGrid>
      </Box>

      <Box maxWidth="1300px" width="100%">
        <SimpleGrid
          position="relative"
          columns={[1, 1, 1, 2]}
          height={['initial', 'initial', 'initial', '325px']}
          mt="50px"
          pb={['3rem', '3rem', '3rem', 0]}
        >
          <Image
            boxShadow="1px 5px 20px rgba(0, 0, 0,.2)"
            width="100%"
            rounded="5px"
            maxWidth={['initial', 'initial', 'initial', '650px']}
            height="340px"
            src={heroAbout}
            zIndex={2}
            alt="About hero"
          />

          <Stack
            zIndex={2}
            pt="2.5rem"
            align="center"
            spacing="25px"
            textAlign={['center', 'center', 'center', 'initial']}
            width="100%"
            maxWidth="500px"
            margin={['0 auto', '0 auto', '0 auto', 'initial']}
            pl={['initial', 'initial', 'initial', '40px']}
          >
            <Text
              as="h3"
              fontWeight="bold"
              color="#0F1112"
              fontSize="1.5rem"
              lineHeight={1}
              mb="1.5rem"
              pt={['0', '0', '0', '4rem']}
            >
              It could be in your roof, ceiling, walls or flooring, rest assured
              Jesco will remove it safely, at a competitive price.
            </Text>

            <Text as="h3" color="#5A5E63">
              Jesco&apos;s quality process covers asbestos identification, risk
              assessment and recommended control measures. Jesco undertakes all
              manner of asbestos removal work, regardless of where the asbestos
              is.
            </Text>
          </Stack>

          <Box
            position="absolute"
            bottom="0"
            right="0"
            left="0"
            zIndex={1}
            height={['300px', '300px', '300px', '275px']}
            backgroundImage={`url(${rectangleAbout})`}
            backgroundPosition="top center"
          />
        </SimpleGrid>
      </Box>

      <Box maxWidth="1300px" width="100%">
        <SimpleGrid columns={[1, 1, 1, 2]}>
          <Flex
            id="removal"
            pt={['50px', '50px', '50px', '100px']}
            pb="60px"
            pl={['10px', '20px', '20px', '70px']}
            pr={['10px', '20px', '20px', '40px']}
            direction="column"
            bg="#00A2AF"
          >
            <Text
              as="h1"
              width={['initial', 'initial', 'initial', '150px']}
              fontSize="2rem"
              lineHeight={1}
              color="white"
              fontWeight="bold"
              mb="40px"
            >
              Asbestos Removal
            </Text>
            <Flex direction={['column', 'column', 'column', 'row']}>
              <Stack spacing="20px" mb={['40px', '40px', '40px', 'initial']}>
                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Fast
                  </Text>
                </Flex>

                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Easy
                  </Text>
                </Flex>

                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Safe
                  </Text>
                </Flex>
              </Stack>

              <Stack spacing="15px" ml={[0, 0, 0, '50px']}>
                <Text as="h3" color="#C7E3F0">
                  By law, the removal of asbestos can only be managed by
                  licenced experts and there is no one better placed than Jesco
                  Asbestos Removal to make asbestos disappear ‘without a trace’.
                </Text>

                <Text as="h3" color="#C7E3F0">
                  Jesco is incredibly experienced and provides practical
                  guidance for clients concerned about asbestos in their
                  properties, from small workshops to multi story apartment
                  blocks and large commercial buildings.
                </Text>

                <Text as="h3" color="#C7E3F0">
                  As a specialist asbestos consultancy, Jesco leads the way in
                  the processes employed to safely remove asbestos. With decades
                  of experience in the United States and Australia, Jill Krogh
                  and Arthur Watson are experts, proud to be trusted by the
                  Australian Government who has issued Jesco with a licence for
                  the construction industry.
                </Text>

                <Text as="h3" color="#C7E3F0">
                  Engaging Jesco Asbestos Removal is a wise and safe decision
                </Text>
              </Stack>
            </Flex>
          </Flex>

          <Flex
            id="assessment"
            pt={['50px', '50px', '50px', '100px']}
            pb="60px"
            pl={['10px', '20px', '20px', '70px']}
            pr={['10px', '20px', '20px', '40px']}
            direction="column"
            bg="blue.500"
          >
            <Text
              as="h1"
              width={['initial', 'initial', 'initial', '150px']}
              fontSize="2rem"
              lineHeight={1}
              color="white"
              fontWeight="bold"
              mb="40px"
            >
              Asbestos Assessment
            </Text>
            <Flex direction={['column', 'column', 'column', 'row']}>
              <Stack spacing="20px" mb={['40px', '40px', '40px', 'initial']}>
                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Independent
                  </Text>
                </Flex>

                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Impartial
                  </Text>
                </Flex>

                <Flex align="center">
                  <Box
                    mr="10px"
                    bg="#004071"
                    rounded="100%"
                    height="12px"
                    width="12px"
                  />
                  <Text as="h2" color="white" fontSize="1.2rem">
                    Integral
                  </Text>
                </Flex>
              </Stack>

              <Stack spacing="15px" ml={[0, 0, 0, '50px']}>
                <Text as="h1" color="#C7E3F0">
                  The ACT requires all asbestos assessment work to be carried
                  out by a licensed asbestos assessor to ensure clarity and
                  safety.
                </Text>

                <Text as="h2" color="#C7E3F0">
                  Jesco is not only licenced in asbestos removal, the principals
                  have decades of experience and evidence based knowledge on the
                  implications, should asbestos be present. Jesco can provide
                  you with everything you need to know, by sample testing.
                </Text>

                <Text as="h2" color="#C7E3F0">
                  The Jesco Asbestos Assessment team follow strict regulations
                  and processes when testing for toxic material. Jesco’s top
                  priority is to ensure that everyone involved is safe from
                  exposure.
                </Text>

                <Text as="h2" color="#C7E3F0">
                  If you’re feeling apprehensive about the unknown, it’s best to
                  treat the material concerned as if it does contain toxic
                  fibres until Jesco can confirm otherwise.
                </Text>

                <Text as="h2" color="#C7E3F0">
                  Trust your investment to Jesco Asbestos Assessment.
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Box>
      <Box maxWidth="1300px" width="100%">
        <Flex
          id="credentials"
          pt="50px"
          pb="60px"
          pl={['10px', '20px', '20px', '70px']}
          pr={['10px', '20px', '20px', '40px']}
          direction="column"
          bg="#0E0F11"
        >
          <Text
            as="h1"
            width={['initial', 'initial', 'initial', '150px']}
            fontSize="2rem"
            lineHeight={1}
            color="white"
            fontWeight="bold"
            mb="20px"
          >
            Expert Credentials
          </Text>

          <Tabs display={['intiial', 'intiial', 'intiial', 'none']}>
            <TabList color="#535354" border="none">
              <Tab
                fontSize="1.4rem"
                borderBottom="1px solid"
                borderColor="transparent"
                _selected={{
                  color: 'white',
                  borderColor: 'white',
                }}
                _hover={{
                  color: '#aaa',
                  borderColor: '#aaa',
                }}
                _focus={{
                  color: 'white',
                  borderColor: 'white',
                }}
              >
                Jill Krogh (Director)
              </Tab>
              <Tab
                fontSize="1.4rem"
                borderBottom="1px solid"
                borderColor="transparent"
                _selected={{
                  color: 'white',
                  borderColor: 'white',
                }}
                _hover={{
                  color: '#aaa',
                  borderColor: '#aaa',
                }}
                _focus={{
                  color: 'white',
                  borderColor: 'white',
                }}
              >
                Arthur Watson (Director)
              </Tab>
            </TabList>

            <TabPanels pt="20px">
              <TabPanel>
                <JillProfile />
              </TabPanel>
              <TabPanel>
                <ArthurProfile />
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Flex display={['none', 'none', 'none', 'flex']}>
            <Flex direction="column" pr="30px">
              <Text as="h1" fontSize="1.4rem" color="white">
                Jill Krogh (Director)
              </Text>

              <JillProfile />
            </Flex>

            <Flex direction="column" pl="30px">
              <Text as="h1" fontSize="1.4rem" color="white">
                Arthur Watson (Director)
              </Text>

              <ArthurProfile />
            </Flex>
          </Flex>
        </Flex>
      </Box>

      <Box maxWidth="1200px" width="100%">
        <SimpleGrid
          position="relative"
          columns={[1, 1, 1, 5]}
          mt={['25px', '25px', '25px', '50px']}
        >
          <Stack
            spacing="20px"
            gridColumn={['span 2']}
            textAlign={['center', 'center', 'center', 'initial']}
            maxWidth={['600px', '600px', '600px', 'initial']}
            margin={['0 auto', '0 auto', '0 auto', 'initial']}
            px={['10px', '10px', '10px', '50px']}
            mb={['30px', '30px', '30px', 'initial']}
            align={['center', 'center', 'center', 'initial']}
          >
            <Text
              as="h1"
              fontWeight="bold"
              color="#0F1112"
              fontSize="2rem"
              lineHeight={1}
              mb="1.5rem"
              pt="1.5rem"
              maxWidth="350px"
            >
              Safety and compliance are in Jesco’s DNA.
            </Text>
            <Link
              href="mailto: admin@jescoasbestos.com.au"
              _hover={{
                textDecoration: 'none',
              }}
            >
              <Button
                color="white"
                rounded="30px"
                width="150px"
                variant="solid"
                variantColor="green"
                fontSize="1.1rem"
              >
                Contact Us
              </Button>
            </Link>
          </Stack>

          <Stack
            spacing="10px"
            gridColumn={['span 3']}
            pr={[0, 0, 0, '50px']}
            textAlign={['center', 'center', 'center', 'initial']}
            maxWidth={['600px', '600px', '600px', 'initial']}
            margin={['0 auto', '0 auto', '0 auto', 'initial']}
            px={['10px', '10px', '10px', '50px']}
          >
            <Text as="h3" color="#5A5E63">
              There are strict regulations around the identification and
              management of asbestos and it’s for a very good reason! When
              asbestos fibres are released and inhaled, the consequences can be
              extremely serious, sometimes fatal.
            </Text>
            <Text as="h3" color="#5A5E63">
              Jill and Arthur’s philosophy is that being extra careful costs
              nothing, but that being careless can cost dearly in more ways than
              one.
            </Text>
            <Text as="h3" color="#5A5E63">
              Jesco is acutely aware of potential hazards and therefore the
              importance of safety, when working with and handling asbestos. All
              team members are guided through Jesco’s strategy on health and
              safety and the measures they have adopted to prevent risks
              associated with toxic fibres.
            </Text>
          </Stack>
        </SimpleGrid>

        <Box
          id="location"
          position="relative"
          maxWidth="1200px"
          width="100%"
          mt="50px"
          height={['400px', '400px', '400px', '470px']}
        >
          <Flex height="100%" zIndex={2} align="center" justify="center">
            <Flex zIndex={2} position="relative">
              <Image alt="Map pin" src={mapPin} height="60px" width="40px" />
              <Box
                left={['20px', '20px', '45px', '45px']}
                top={['65px', '65px', '5px', '5px']}
                position="absolute"
                height="150px"
                width="180px"
                backgroundImage={`url(${mapBubble})`}
                backgroundSize="100%"
                backgroundPosition="center top"
                backgroundRepeat="no-repeat"
              >
                <Stack pl="37px" pt="15px" spacing="5px">
                  <Link
                    rel="noopener noreferrer"
                    href="https://maps.google.com/maps?q=17 Capital Terrace Queanbeyan NSW, 2620"
                    target="_blank"
                    fontWeight="bold"
                    color="blue.500"
                  >
                    Jesco
                  </Link>
                  <Stack spacing="0" color="#5A5E63">
                    <Text fontSize="11px">17 Capital Terrace</Text>
                    <Text fontSize="11px">Queanbeyan NSW 2620</Text>
                  </Stack>
                </Stack>
              </Box>
            </Flex>
          </Flex>
          <Box
            zIndex={1}
            top={0}
            bottom={0}
            left={0}
            right={0}
            position="absolute"
            height={['400px', '400px', '400px', '470px']}
            width="100%"
            backgroundImage={`url(${homeMap})`}
            backgroundSize={['300%', '200%', '150%', '100%']}
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
          />
        </Box>
      </Box>
      <Box maxWidth="1200px" width="100%">
        <SimpleGrid position="relative" columns={[1, 1, 1, 5]}>
          <Flex
            gridColumn="span 3"
            height="100%"
            align="center"
            pl={[0, 0, 0, '100px']}
          >
            <Stack
              spacing="5px"
              isInline
              py="30px"
              justify={['center', 'center', 'center', 'flex-start']}
              flexDirection={['column', 'column', 'row', 'row']}
              width="100%"
              textAlign={['center', 'center', 'center', 'initial']}
            >
              <Text as="h1" fontSize="1.5rem" color="gray.500">
                Asbestos Removed.
              </Text>
              <Text as="h1" fontSize="1.5rem" color="blue.500">
                Peace of mind gained.
              </Text>
            </Stack>
          </Flex>
          <Flex
            gridColumn="span 2"
            height="100%"
            bg="blue.500"
            py="30px"
            align="center"
            justify={['center', 'center', 'center', 'center']}
            px={[0, 0, 0, '60px']}
          >
            <Stack spacing="0px" mr="20px">
              <Text as="h1" fontSize="1.3rem" fontWeight="bold" color="white">
                Tony Hall
              </Text>
              <Text as="h3" fontSize=".9rem" color="white">
                Senior Supervisor
              </Text>
              <Link
                as="h1"
                href="tel:0411-067-700"
                color="#CEE7F2"
                fontSize="1.1rem"
              >
                0411 067 700
              </Link>
            </Stack>

            {/* <Box height="60px" width="2px" bg="#5697B2" />

            <Stack spacing="0px" ml="20px">
              <Text as="h1" fontSize="1.3rem" fontWeight="bold" color="white">
                Arthur Watson
              </Text>
              <Text as="h3" fontSize=".9rem" color="white">
                Managing Director
              </Text>
              <Link
                as="h1"
                href="tel:0419-629-864"
                color="#CEE7F2"
                fontSize="1.1rem"
              >
                0419 629 864
              </Link>
            </Stack> */}
          </Flex>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};
