import React from 'react';
import {
  Button,
  Stack,
  Flex,
  Text,
  Avatar,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/core';

export const JillProfile = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing="10px">
      <Flex align="center" direction={['column', 'column', 'row']}>
        <Avatar size="2xl" mr="30px" my={['20px', '20px', 0]} />
        <Text color="#ccc">
          Jill’s project portfolio is testament to her skills and experience.
          She has been engaged by both private and government clients in the
          United States and Australia. She has been Director of Jesco since
          2015.
        </Text>
      </Flex>
      {/* 
      {isOpen && (
        <Stack spacing="10px">
          <Text color="#ccc">Jill&apos;s experience covers</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              Intrusive and non-intrusive asbestos and hazardous materials
              surveys
            </ListItem>
            <ListItem>
              Developing Asbestos Management Plans and Registers adhering to
              Codes of Practice and other relevant regulations
            </ListItem>
            <ListItem>Developing WH&S Manuals</ListItem>
            <ListItem>
              Developing site specific WH&S Emergency Plans & WH&S Site
              Management Plans
            </ListItem>
            <ListItem>
              Developing site specific Asbestos Removal Control Plan (ARCP) and
              Safe Work Method Statement (SWMS)
            </ListItem>
            <ListItem>Facilitating toolbox talks</ListItem>
            <ListItem>
              Conducting asbestos air monitoring and collect bulk sampling
            </ListItem>
            <ListItem>
              Carrying out post-removal asbestos clearance inspection
            </ListItem>
            <ListItem>
              Supervising both large and small-scale non-friable and friable
              asbestos removal projects
            </ListItem>
            <ListItem>
              Developing and preparing remediation plans for both large and
              small projects soil projects
            </ListItem>
            <ListItem>
              Overseeing asbestos removal staff, fostering a cohesive productive
              team environment with a focus on WH&S
            </ListItem>
            <ListItem>
              Organising employee training, medical certifications, RPE fit
              testing, and other requirements
            </ListItem>
            <ListItem>
              Working closely with business partner Arthur Watson on the course
              material and presentation for asbestos classes provided by the
              Master Builders Association Group Training, Fyshwick ACT
            </ListItem>
            <ListItem>
              Presenting MBA Group Training, ACT – “Asbestos Information” – Two
              hour sessions
            </ListItem>
            <ListItem>
              Developing a database system to capture accurate and complete
              asbestos survey data for risk assessment and control measures
            </ListItem>
            <ListItem>
              Providing high end services to all sectors including government,
              retail and commercial, Defence, education, and private clients
            </ListItem>
            <ListItem>
              Carrying out asbestos surveys and preparing asbestos management
              reports
            </ListItem>
          </List>

          <Text color="#ccc">Positions Held</Text>
          <List color="#999999" styleType="disc" ml="10px">
            <ListItem>
              Asbestos Supervisor – AAC, Inc., Minneapolis, MN, USA
            </ListItem>
            <ListItem>
              Oversaw the inspection of 23 million square feet of building space
              under the Asbestos Hazard Emergency Response Act (AHERA)
            </ListItem>
            <ListItem>
              Prepared technical specifications for asbestos removal projects
            </ListItem>
            <ListItem>
              Supervised large scale friable and non-friable asbestos removal
              projects from start to finish for a number of school districts
              including Minneapolis (largest district in Minnesota), Hopkins,
              St. Louis Park, Belle Plaines, and Farmington
            </ListItem>
            <ListItem>
              Developed database system to capture accurate and complete
              asbestos survey data for risk assessment and control measures
            </ListItem>
            <ListItem>Director Jesco Asbestos Removal since 2015</ListItem>
          </List>

          <Stack mt="20px">
            <Text color="white">Lic ARA00003</Text>
            <Text color="white">Lic AA00020</Text>
          </Stack>
        </Stack>
      )}

      <Button
        variantColor="transparent"
        variant="solid"
        mt={10}
        onClick={onToggle}
      >
        {isOpen ? 'Show less' : 'Show more'}
      </Button> */}
    </Stack>
  );
};
